import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby'

import Layout from '../layouts';

const NotFoundPage = () => (
  <Layout>
  <div id="start" className="content">
  <Helmet>
    <title>Pagina niet gevonden - Sureventure Camperbouw</title>
  </Helmet>
    <section className="panel content-text">
      <h1>Pagina niet gevonden - 404</h1>
      <div className="intro">Deze pagina bestaat niet (meer).</div>
      <div>
        <div>
          <Link to="/">Homepage</Link>
        </div>
        <div>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
    </section>
  </div>
</Layout>
)

export default NotFoundPage
